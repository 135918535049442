function admin_find_student(student_id, callback) {
  $.ajax({
    url: '/students/' + student_id,
    dataType: 'json',
  }).done(function(data) {
    callback(data);
  });
}

function admin_populate_student_details(student) {
  $('#student-details').addClass('hidden');
  $('#student-lookup-error').addClass('hidden');
  $('#student-first-name').html('');
  $('#student-last-name').html('');
  $('#student-school').html('');
  if (student.status == 'found') {
    $('#student-details').removeClass('hidden');
    $('#student-first-name').html(student.firstname);
    $('#student-last-name').html(student.lastname);
    $('#student-school').html(student.school);
  } else {
    $('#student-lookup-error').removeClass('hidden');
    $('#student-details').addClass('hidden');
  }
}

export default () => {
  // System Admin add Parent

  $('#student-lookup, #student-lookup-fieldset').click(function(event) {
    event.preventDefault();
    admin_find_student($('#parent_student_id').val(), admin_populate_student_details);
  });
};
