function find_student(student_id, student_last_name, callback) {
  $.ajax({
    url: '/students/' + student_id,
    data: { lastname: student_last_name },
    dataType: 'json'
  }).done(function(data) {
    callback(data);
  });
}

function set_student_step_2(student) {
  $('.step-2').addClass('hidden');
  $('#student-lookup-error').addClass('hidden');
  $('#step-2 #first-name').html('');
  $('#step-2 #last-name').html('');
  $('#step-2 #school-name').html('');
  if (student.status == 'found') {
    $('#step-2').removeClass('hidden');
    $('#step-2 #first-name').html(student.firstname);
    $('#step-2 #last-name').html(student.lastname);
    $('#step-2 #school-name').html(student.school);
  } else if (student.status == 'parent') {
    $('#student-parent-error').removeClass('hidden');
    $('#student-parent-error #s-student_id').html(student.student_id);
    $('#student-parent-error #s-first-name').html(student.firstname);
    $('#student-parent-error #s-last-name').html(student.lastname);
    $('#student-parent-error #s-school-name').html(student.school);
  } else {
    $('#student-lookup-error').removeClass('hidden');
    $('#step-3').addClass('hidden');
  }

  $('#cancel').click(function(event) {
    event.preventDefault();
    $('.step-2').addClass('hidden');
    $('#step-3').addClass('hidden');
  });

  $('#continue').click(function(event) {
    event.preventDefault();
    $('#step-1-submit').addClass('hidden');
    $('#cancel').addClass('hidden');
    $('#continue').addClass('hidden');
    $('#step-3').removeClass('hidden');
  });
}

export default () => {
  // Parent Registration
  $('#step-1-submit').click(function(event) {
    event.preventDefault();
    find_student($('#parent_student_student_id').val(), $('#parent_student_last_name').val(), set_student_step_2);
  });

  // $('form#new_parent.new-parent-form').submit(function(event) {
  //   // if (!$('#step-2').filter(':visible').length || !$('#step-3').filter(':visible').length) {
  //     event.preventDefault();
  //     find_student($('#parent_student_student_id').val(), $('#parent_student_last_name').val(), set_student_step_2);
  //   // }
  // });
};

$(document).ready(function() {
  const newParentForm = document.getElementById('new-parent-form');
  if (newParentForm) {
    $(window).keydown(function(event) {
      if(event.keyCode == 13) {
        event.preventDefault();
        window.alert("Please click buttons to submit the form.");
        return false;
      }
    });
  }
});
