import Selectr from 'mobius1-selectr';

export default () => {

  const select1 = document.querySelector('.selecting');
  const select2 = document.querySelector('.selecting-2');
  const select3 = document.querySelector('.selecting-3');
  const loaded = document.querySelector('.selectr-container');

  if (select1 !== null && !loaded) {
    new Selectr('.selecting', {
      searchable: true,
      clearable: true,
      defaultSelected: true,
      allowDeselect: true,
      multiple: false,
      data: [{loaded: true}]
    });
  }

  if (select2 !== null && !loaded) {
    new Selectr('.selecting-2', {
      searchable: true,
      clearable: true,
      defaultSelected: true,
      allowDeselect: true,
      multiple: false,
    });
  }

  if (select3 != null && !loaded) {
    new Selectr('.selecting-3', {
      searchable: true,
      clearable: true,
      defaultSelected: true,
      allowDeselect: true,
      multiple: false,
    });
  }
};
