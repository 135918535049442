import zxcvbn from 'zxcvbn';
import tippy from 'tippy.js';

const strength = {
  0: 'Worst',
  1: 'Bad',
  2: 'Weak',
  3: 'Good',
  4: 'Strong',
};

export default () => {
  const password = document.getElementById('parent_password');
  const meter = document.getElementById('password-strength-meter');
  const text = document.getElementById('password-strength-text');

  if (!password && !meter && !text) return;

  password.addEventListener('input', () => {
    const val = password.value;
    const result = zxcvbn(val);

    // Update the password strength meter
    meter.value = result.score;

    // Update the text indicator
    if (val !== '') {
      text.innerHTML = `<strong></strong>Strength: ${strength[result.score]}</strong>`;
      const instance = tippy('#parent_password', {
        arrow: true,
        content: `${result.feedback.warning} ${result.feedback.suggestions}`,
      });

      // instance.show();
    } else {
      text.innerHTML = '';
    }
  });
};
