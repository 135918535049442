// function increment_day_total(meal_type, day_index) {
//   var $table = $('#credit_usage'),
//     $total_row = $table.find('tr.totals.overall'),
//     $type_total_row = $table.find('tr.totals.' + meal_type);
//   $.each([$total_row, $type_total_row], function(idx, rows) {
//     rows.each(function() {
//       var row = $(this);
//       var cells = row.find('th.day_total span');
//       ($day_cell = cells.eq(day_index)), (current_total = parseInt($day_cell.html(), 10));
//       $day_cell.html(current_total + 1);
//     });
//   });
// }

// function decrement_day_total(meal_type, day_index) {
//   var $table = $('#credit_usage'),
//     $total_row = $table.find('tr.totals.overall'),
//     $type_total_row = $table.find('tr.totals.' + meal_type);
//   $.each([$total_row, $type_total_row], function(idx, rows) {
//     rows.each(function() {
//       var row = $(this);
//       var cells = row.find('th.day_total span');
//       ($day_cell = cells.eq(day_index)), (current_total = parseInt($day_cell.html(), 10));
//       $day_cell.html(current_total - 1);
//     });
//   });
// }

export default () => {
  $('#credit_management').on('ajax:before', 'form', () => {
    $(this)
      .find("input[type='submit']")
      .prop('disabled', true);
  });

  $('#credit_management').on('ajax:complete', 'form', e => {
    $(this)
      .find("input[type='submit']")
      .prop('disabled', false);
  });

  $('body').on('click', 'table#credit_usage .toggle', e => {
    const order = $(e.target).data('order');
    console.log('clicked', order);
    $('td[data-day=' + $(e.target).data('day') + "] input[type='checkbox']").each(function(idx, chb) {
      // console.log(chb);
      if ((order == 'select' && !$(chb).is(':checked')) || (order == 'deselect' && $(chb).is(':checked'))) {
        $(chb).trigger('click');
      }
    });
    return false;
  });

  $('body').on('change', "table#credit_usage td.credit-management input[type='checkbox']", e => {
    var checkbox = $(this),
      checked = checkbox.prop('checked'),
      meal_cost = checkbox.data('cost'),
      row = checkbox.closest('tr'),
      cell = checkbox.parent('td'),
      remaining = row.data('remaining'),
      deducatable = remaining != 'fsm' && remaining != 'ufsm',
      meal_type = deducatable ? 'paid' : remaining,
      enabled = row.find("td.credit-management input[type='checkbox']:not(.disabled)"),
      day_index = cell
        .siblings('.credit-management')
        .addBack()
        .index(cell);
    if (deducatable) {
      if (checked) {
        remaining = remaining - meal_cost;
        row.data('remaining', remaining);
      } else {
        remaining = remaining + meal_cost;
        row.data('remaining', remaining);
      }
      enabled.each(function() {
        var check = $(this),
          cost = check.data('cost');
        if (remaining >= cost && !check.is('.disabled')) {
          check.prop('disabled', false).attr('title', '');
        }
        // else if (remaining < cost && !check.is(':checked')) {
        //   check.prop('disabled', true).attr('title', "This student's balance won't cover a meal");
        // }
      });
    }

    // if (checked) {
    //   increment_day_total(meal_type, day_index);
    // } else {
    //   decrement_day_total(meal_type, day_index);
    // }
  });
};
