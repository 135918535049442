const UpdateMeals = (cost, el) => val => {
  const pence = parseFloat(val, 2) * 100;
  el.innerHTML = Math.floor(pence / cost);
};

const moveAmount = (amount, updateMeals, increase) => e => {
  e.preventDefault();
  const value = parseFloat(amount.value);

  const newValue = value + increase;

  if (newValue >= 2.5) {
    amount.value = newValue;
    updateMeals(newValue);
  }
};

export default () => {
  const selector = document.getElementById('purchase_amount_selector');
  const amount = document.getElementById('purchase_amount');

  if (!amount || !selector) return;

  const increase = selector.querySelector('.increment');
  const decrease = selector.querySelector('.decrement');
  const meals = selector.querySelector('p strong span');

  if (!increase || !decrease || !meals) return;

  const updateMeals = UpdateMeals(selector.dataset.mealCost, meals);

  amount.addEventListener('change', e => {
    e.target.value = parseFloat(e.target.value).toFixed(2);
    updateMeals(e.target.value);
  });

  increase.addEventListener('click', moveAmount(amount, updateMeals, 1));

  decrease.addEventListener('click', moveAmount(amount, updateMeals, -1));

  updateMeals(amount.value);
};
