export default () => {
  $('select.autoSubmit').change(function(e) {
    var confirmText = $(this).data('confirm-submit');
    console.log(confirmText);
    if (confirmText && confirm(confirmText)) {
      $(this)
        .closest('form')
        .submit();
    } else if (!confirmText) {
      $(this)
        .closest('form')
        .submit();
    }
  });
};
