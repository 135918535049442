import Pikaday from 'pikaday';
// import { format } from 'date-fns';

const AddPicker = picker => {
  new Pikaday({
    field: picker,
    firstDay: 1,
    format: 'DD-MM-YYYY',
    toString(date, format) {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      const year = date.getFullYear();
      if(day < 10){day='0'+day};
      if(month<10){month='0'+month};
      return `${day}-${month}-${year}`;
    },
    parse(dateString, format) {
      const parts = dateString.split('-');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1;
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
    },
  });
};

window.AddPicker = AddPicker;

export default () => {
  const pickers = document.querySelectorAll('.date-pick');

  [...pickers].forEach(AddPicker);
};
