import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = jQuery;

import autoNumeric from 'autonumeric';
window.autoNumeric = autoNumeric;

// import kalendae from 'kalendae';
// window.kalendae = kalendae;

// import placeholder from 'jquery-placeholder';
// window.placeholder = placeholder;

import 'jquery_nested_form';


