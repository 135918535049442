const SetDialog = btn => {
  const dialog = document.getElementById(btn.dataset.dialog);
  const close = document.getElementById(btn.dataset.dialogClose);

  btn.addEventListener('click', e => {
    e.preventDefault();
    dialog.showModal();
  });

  dialog.addEventListener('click', event => {
    if (event.target === dialog) {
      dialog.close('cancelled');
    }
  });

  close.addEventListener('click', () => {
    dialog.close('cancelled');
  });
};

export default () => {
  const dialogs = [...document.querySelectorAll('dialog')];

  if (dialogs.length === 0) return;

  if (typeof HTMLDialogElement !== 'function') {
    import(/* webpackChunkName: "dialog-polyfill" */ 'dialog-polyfill').then(({ default: dialogPolyfill }) => {
      dialogs.forEach(dialog => dialogPolyfill.registerDialog(dialog));
    });
  }

  const btns = [...document.querySelectorAll('[data-dialog]')];

  btns.forEach(SetDialog);
  window.SetDialog = SetDialog;
};
