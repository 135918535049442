import GeneralSprite from '../../../assets/images/general.svg';

const expanderWrapper = (inner, pressed = false, css = '', btnCss = '') => `
    <div class="expanding_panels__expander">
      <div class='expanding_panels__original ${css}'>${inner}</div>
      <div class='expanding_panels__btn-wrapper ${btnCss}'>
        <button class="expanding_panels__btn" aria-pressed="${pressed}" role="button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.09 7.75" class="expanding_panels__icon">
            <title>Open</title>
            <path d="M.66.75l4,3.5-4,3.5" transform="translate(-0.33 -0.37)" style="fill:none;stroke-miterlimit:10"/>
          </svg>
      </button>
      </div>
    </div>
`;

export const unwrapButton = (el) => {
  if (el instanceof Element) {
    const original = el.querySelector('.expanding_panels__original');
    const btn = el.querySelector('.expanding_panels__expander');
    // Remove button
    if (btn instanceof Element) {
      el.removeChild(btn);
    }
    // Replace original
    if (original instanceof Element) {
      const unwrappedButton = original.innerHTML;

      el.insertAdjacentHTML('beforeend', unwrappedButton);
    }
  }
};

export default ({ el, expanders }) => {
  const contents = expanders.reduce(
    (conts, expander) => conts + expander.outerHTML,
    ''
  );
  const expanderBtn = expanderWrapper(
    contents,
    el.dataset.expandedDefault,
    el.dataset.expanderClass,
    el.dataset.buttonClass
  );
  expanders.forEach((expander) => {
    el.removeChild(expander);
  });

  el.insertAdjacentHTML('afterbegin', expanderBtn);

  const btn = el.querySelector('.expanding_panels__btn');

  return btn instanceof Element ? btn : null;
};
