import AutoSubmit from './autosubmit';
import datepicker from './datepicker';
import Dialog from './dialog';
import ParentReg from './parent_registration';
import PasswordStrength from './password_strength';
import CookiePrompt from './cookie_prompt';
import IEBanner from './ie_banner';
import CreditUsage from './credit_usage';
import PurchaseAmount from './purchase_amount';
import SystemAdmin from './system_admin';
import Faqs from './expanding_panels';
import Selectr from './fancy_select';


(() => {
  AutoSubmit();
  CookiePrompt();
  datepicker();
  Dialog();
  ParentReg();
  PasswordStrength();
  IEBanner();
  CreditUsage();
  SystemAdmin();
  PurchaseAmount();
  Faqs();
  Selectr();
})();

document.addEventListener('turbo:load', () => {
  AutoSubmit();
  CookiePrompt();
  datepicker();
  Dialog();
  ParentReg();
  PasswordStrength();
  IEBanner();
  CreditUsage();
  SystemAdmin();
  PurchaseAmount();
  Faqs();
  Selectr();
});